
import React, {Component} from 'react'
import PropTypes from 'prop-types'

const CLASS_NAME = 'octicon octicon-eye'
class EyeIcon extends Component {
  render() {
    let defaults = {
      height: 16,
      width: 16,
      viewBox: '0 0 16 16',
      'aria-hidden': true
    }

    let {className, size} = this.props
    if (className) {
      className = CLASS_NAME + ' ' + className
    } else {
      className = CLASS_NAME
    }

    if (size === 'mega') {
      defaults.height *= 2
      defaults.width *= 2
    }

    return (
      <svg {...defaults} {...this.props} className={className}>
        <path fillRule="evenodd" d="M8.06 2C3 2 0 8 0 8s3 6 8.06 6C13 14 16 8 16 8s-3-6-7.94-6zM8 12c-2.2 0-4-1.78-4-4 0-2.2 1.8-4 4-4 2.22 0 4 1.8 4 4 0 2.22-1.78 4-4 4zm2-4c0 1.11-.89 2-2 2-1.11 0-2-.89-2-2 0-1.11.89-2 2-2 1.11 0 2 .89 2 2z"/>
      </svg>
    )
  }
}
EyeIcon.propTypes = {
  className: PropTypes.string
}
export default EyeIcon
