
import React, {Component} from 'react'
import PropTypes from 'prop-types'

const CLASS_NAME = 'octicon octicon-arrow-small-down'
class ArrowSmallDownIcon extends Component {
  render() {
    let defaults = {
      height: 16,
      width: 6,
      viewBox: '0 0 6 16',
      'aria-hidden': true
    }

    let {className, size} = this.props
    if (className) {
      className = CLASS_NAME + ' ' + className
    } else {
      className = CLASS_NAME
    }

    if (size === 'mega') {
      defaults.height *= 2
      defaults.width *= 2
    }

    return (
      <svg {...defaults} {...this.props} className={className}>
        <path fillRule="evenodd" d="M4 7V5H2v2H0l3 4 3-4z"/>
      </svg>
    )
  }
}
ArrowSmallDownIcon.propTypes = {
  className: PropTypes.string
}
export default ArrowSmallDownIcon
