
import React, {Component} from 'react'
import PropTypes from 'prop-types'

const CLASS_NAME = 'octicon octicon-repo-push'
class RepoPushIcon extends Component {
  render() {
    let defaults = {
      height: 16,
      width: 12,
      viewBox: '0 0 12 16',
      'aria-hidden': true
    }

    let {className, size} = this.props
    if (className) {
      className = CLASS_NAME + ' ' + className
    } else {
      className = CLASS_NAME
    }

    if (size === 'mega') {
      defaults.height *= 2
      defaults.width *= 2
    }

    return (
      <svg {...defaults} {...this.props} className={className}>
        <path fillRule="evenodd" d="M4 3H3V2h1v1zM3 5h1V4H3v1zm4 0L4 9h2v7h2V9h2L7 5zm4-5H1C.45 0 0 .45 0 1v12c0 .55.45 1 1 1h4v-1H1v-2h4v-1H2V1h9.02L11 10H9v1h2v2H9v1h2c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1z"/>
      </svg>
    )
  }
}
RepoPushIcon.propTypes = {
  className: PropTypes.string
}
export default RepoPushIcon
