
import React, {Component} from 'react'
import PropTypes from 'prop-types'

const CLASS_NAME = 'octicon octicon-primitive-dot'
class PrimitiveDotIcon extends Component {
  render() {
    let defaults = {
      height: 16,
      width: 8,
      viewBox: '0 0 8 16',
      'aria-hidden': true
    }

    let {className, size} = this.props
    if (className) {
      className = CLASS_NAME + ' ' + className
    } else {
      className = CLASS_NAME
    }

    if (size === 'mega') {
      defaults.height *= 2
      defaults.width *= 2
    }

    return (
      <svg {...defaults} {...this.props} className={className}>
        <path fillRule="evenodd" d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"/>
      </svg>
    )
  }
}
PrimitiveDotIcon.propTypes = {
  className: PropTypes.string
}
export default PrimitiveDotIcon
