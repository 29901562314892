
import React, {Component} from 'react'
import PropTypes from 'prop-types'

const CLASS_NAME = 'octicon octicon-arrow-right'
class ArrowRightIcon extends Component {
  render() {
    let defaults = {
      height: 16,
      width: 10,
      viewBox: '0 0 10 16',
      'aria-hidden': true
    }

    let {className, size} = this.props
    if (className) {
      className = CLASS_NAME + ' ' + className
    } else {
      className = CLASS_NAME
    }

    if (size === 'mega') {
      defaults.height *= 2
      defaults.width *= 2
    }

    return (
      <svg {...defaults} {...this.props} className={className}>
        <path fillRule="evenodd" d="M10 8L4 3v3H0v4h4v3z"/>
      </svg>
    )
  }
}
ArrowRightIcon.propTypes = {
  className: PropTypes.string
}
export default ArrowRightIcon
