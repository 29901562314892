
import React, {Component} from 'react'
import PropTypes from 'prop-types'

const CLASS_NAME = 'octicon octicon-issue-reopened'
class IssueReopenedIcon extends Component {
  render() {
    let defaults = {
      height: 16,
      width: 14,
      viewBox: '0 0 14 16',
      'aria-hidden': true
    }

    let {className, size} = this.props
    if (className) {
      className = CLASS_NAME + ' ' + className
    } else {
      className = CLASS_NAME
    }

    if (size === 'mega') {
      defaults.height *= 2
      defaults.width *= 2
    }

    return (
      <svg {...defaults} {...this.props} className={className}>
        <path fillRule="evenodd" d="M8 9H6V4h2v5zm-2 3h2v-2H6v2zm6.33-2H10l1.5 1.5c-1.05 1.33-2.67 2.2-4.5 2.2A5.71 5.71 0 0 1 1.3 8c0-.34.03-.67.09-1H.08C.03 7.33 0 7.66 0 8c0 3.86 3.14 7 7 7 2.19 0 4.13-1.02 5.41-2.59L14 14v-4h-1.67zM1.67 6H4L2.5 4.5C3.55 3.17 5.17 2.3 7 2.3c3.14 0 5.7 2.56 5.7 5.7 0 .34-.03.67-.09 1h1.31c.05-.33.08-.66.08-1 0-3.86-3.14-7-7-7-2.19 0-4.13 1.02-5.41 2.59L0 2v4h1.67z"/>
      </svg>
    )
  }
}
IssueReopenedIcon.propTypes = {
  className: PropTypes.string
}
export default IssueReopenedIcon
