
import React, {Component} from 'react'
import PropTypes from 'prop-types'

const CLASS_NAME = 'octicon octicon-mail-reply'
class MailReplyIcon extends Component {
  render() {
    let defaults = {
      height: 16,
      width: 12,
      viewBox: '0 0 12 16',
      'aria-hidden': true
    }

    let {className, size} = this.props
    if (className) {
      className = CLASS_NAME + ' ' + className
    } else {
      className = CLASS_NAME
    }

    if (size === 'mega') {
      defaults.height *= 2
      defaults.width *= 2
    }

    return (
      <svg {...defaults} {...this.props} className={className}>
        <path fillRule="evenodd" d="M6 2.5L0 7l6 4.5v-3c1.73 0 5.14.95 6 4.38 0-4.55-3.06-7.05-6-7.38v-3z"/>
      </svg>
    )
  }
}
MailReplyIcon.propTypes = {
  className: PropTypes.string
}
export default MailReplyIcon
