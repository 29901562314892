
import React, {Component} from 'react'
import PropTypes from 'prop-types'

const CLASS_NAME = 'octicon octicon-browser'
class BrowserIcon extends Component {
  render() {
    let defaults = {
      height: 16,
      width: 14,
      viewBox: '0 0 14 16',
      'aria-hidden': true
    }

    let {className, size} = this.props
    if (className) {
      className = CLASS_NAME + ' ' + className
    } else {
      className = CLASS_NAME
    }

    if (size === 'mega') {
      defaults.height *= 2
      defaults.width *= 2
    }

    return (
      <svg {...defaults} {...this.props} className={className}>
        <path fillRule="evenodd" d="M5 3h1v1H5V3zM3 3h1v1H3V3zM1 3h1v1H1V3zm12 10H1V5h12v8zm0-9H7V3h6v1zm1-1c0-.55-.45-1-1-1H1c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V3z"/>
      </svg>
    )
  }
}
BrowserIcon.propTypes = {
  className: PropTypes.string
}
export default BrowserIcon
