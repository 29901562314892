
import React, {Component} from 'react'
import PropTypes from 'prop-types'

const CLASS_NAME = 'octicon octicon-dash'
class DashIcon extends Component {
  render() {
    let defaults = {
      height: 16,
      width: 8,
      viewBox: '0 0 8 16',
      'aria-hidden': true
    }

    let {className, size} = this.props
    if (className) {
      className = CLASS_NAME + ' ' + className
    } else {
      className = CLASS_NAME
    }

    if (size === 'mega') {
      defaults.height *= 2
      defaults.width *= 2
    }

    return (
      <svg {...defaults} {...this.props} className={className}>
        <path fillRule="evenodd" d="M0 7v2h8V7z"/>
      </svg>
    )
  }
}
DashIcon.propTypes = {
  className: PropTypes.string
}
export default DashIcon
